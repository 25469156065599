export const REQUEST_CREATE_WISHLIST = "REQUEST_CREATE_WISHLIST"
export const SUCCESS_CREATE_WISHLIST = "SUCCESS_CREATE_WISHLIST"
export const    FAIL_CREATE_WISHLIST    = "FAIL_CREATE_WISHLIST"
export const REQUEST_GET_WISHLIST = "REQUEST_GET_WISHLIST"
export const SUCCESS_GET_WISHLIST = "SUCCESS_GET_WISHLIST"
export const    FAIL_GET_WISHLIST    = "FAIL_GET_WISHLIST"
export const REQUEST_CREATE_BAG = "REQUEST_CREATE_BAG"
export const SUCCESS_CREATE_BAG = "SUCCESS_CREATE_BAG"
export const    FAIL_CREATE_BAG    = "FAIL_CREATE_BAG"
export const REQUEST_GET_BAG = "REQUEST_GET_BAG"
export const SUCCESS_GET_BAG = "SUCCESS_GET_BAG"
export const    FAIL_GET_BAG    = "FAIL_GET_BAG"
export const SUCCESS_UPDATE_QTY_BAG = "REQUEST_UPDATE_QTY_BAG"
export const REQUEST_UPDATE_QTY_BAG = "SUCCESS_UPDATE_QTY_BAG"
export const    FAIL_UPDATE_QTY_BAG = "   FAIL_UPDATE_QTY_BAG"
export const SUCCESS_DELETE_BAG = "REQUEST_DELETE_BAG"
export const REQUEST_DELETE_BAG = "SUCCESS_DELETE_BAG"
export const    FAIL_DELETE_BAG = "   FAIL_DELETE_BAG"
export const SUCCESS_DELETE_WISH = "REQUEST_DELETE_WISH"
export const REQUEST_DELETE_WISH = "SUCCESS_DELETE_WISH"
export const    FAIL_DELETE_WISH = "   FAIL_DELETE_WISH"
export const CLEAR_ERRORS    = "CLEAR_ERRORS   "