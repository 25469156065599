import React from 'react'
import './Loader.css'

const Loader = () => {
    return (
        <div>
        <div class="loadingio-spinner-rolling-wt3arydyfh">
            <div class="ldio-4fl1682dwhu">
                <div></div>
            </div>
        </div>
        </div>
    )
}

export default Loader